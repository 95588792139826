/* eslint-disable camelcase */
import { gtag } from "#js/components/utils"
import insightsClient from "search-insights"

insightsClient("init", {
  appId: globalThis.algoliaAppId,
  apiKey: globalThis.algoliaAPIKey,
  authenticatedUserToken: globalThis.userId,
})

/**
 * Send a booking conversion event to various analytics platforms.
 *
 * Forward the information about the booked item
 * and the user to Algolia and Google Analytics.
 * @example
 *
 * window.track.booking({
 *   "item_id": "talk-123",
 *   "item_name": "PyConDE & PyData Berlin 2020",
 *   "item_brand": "PyConDE & PyData Berlin",
 *   "item_category": "talk",
 *   "price": 420.69,
 *   "currency": "EUR",
 *   "quantity": 42,
 * })
 * @param {object} params - The parameters of the booking.
 * @param {string} params.item_id - The unique identifier of the item.
 * @param {string} params.item_name - The name of the item.
 * @param {string} params.item_brand - The brand of the item.
 * @param {string} params.item_category - The category of the item.
 * @param {number} params.price - The price of the item in the currency (default: 0).
 * @param {string} params.currency - The currency of the price (default: 'EUR').
 * @param {number} params.quantity - The quantity of the item (default: 1).
 */

export function booking(
  {
    item_id,
    item_name,
    item_brand,
    item_category,
    price = 0,
    currency = "EUR",
    quantity = 1,
  },
) {
  console.debug("Tracking event: booking", {
    item_id,
    item_name,
    item_brand,
    item_category,
    price,
    currency,
    quantity,
  })
  gtag("event", "purchase", {
    transaction_id: `booking-${item_id}-${globalThis.userId}`,
    value: price,
    currency,
    items: [
      {
        item_id,
        item_name,
        item_brand,
        item_category,
        price,
        quantity,
      },
    ],
  })
  insightsClient("convertedObjectIDs", {
    authenticatedUserToken: globalThis.userId,
    index: globalThis.algoliaIndexName,
    eventName: `${item_category} Conversion`,
    objectIDs: [item_id],
  })
}

/**
 * Send an add to cart event to various analytics platforms.
 *
 * Forward the information about the added item
 * and the user to Algolia and Google Analytics.
 * @example
 *
 * window.track.addToCart({
 *   "item_id": "booking_option-123",
 *   "item_name": "PyConDE & PyData Berlin 2020",
 *   "item_brand": "PyConDE & PyData Berlin",
 *   "item_category": "class",
 *   "affiliation": "provider",
 *   "price": 420.69,
 * })
 * @param {object} params - the analytics paramaters
 * @param {string} params.item_id - the item id
 * @param {string} params.item_name - the item name
 * @param {string} params.item_brand - the item brand
 * @param {string} params.item_category - the item category
 * @param {string} params.affiliation - the item affiliation
 * @param {number} params.price - The price of the item in the currency (default: 0).
 * @param {string} params.currency - The currency of the price (default: 'EUR').
 * @param {number} params.quantity - The quantity of the item (default: 1).
 */
export function addToCart(
  {
    item_id,
    item_name,
    item_brand,
    item_category,
    affiliation,
    price = 0,
    currency = "EUR",
    quantity = 1,
  },
) {
  console.debug("Tracking event: add_to_cart", {
    item_id,
    item_name,
    item_brand,
    item_category,
    affiliation,
    price,
    currency,
    quantity,
  })
  gtag("event", "add_to_cart", {
    transaction_id: `add_to_cart-${item_id}-${globalThis.userId}`,
    value: price,
    currency,
    items: [{
      item_id,
      item_name,
      item_brand,
      item_category,
      affiliation,
      price,
      quantity,
    }],
  })
  insightsClient("addedToCartObjectIDs", {
    authenticatedUserToken: globalThis.userId,
    index: globalThis.algoliaIndexName,
    eventName: `${item_category} Added To Cart`,
    objectIDs: [item_id],
  })
}

/**
 * Send a click item to various analytics platforms.
 *
 * Forward the information about the clicked item
 * and the user to Algolia and Google Analytics.
 * @example
 *
 * window.track.click({
 *  "item_id": "talk-123",
 *  "item_name": "PyConDE & PyData Berlin 2020",
 *  "item_brand": "PyConDE & PyData Berlin",
 *  "affiliation": "PyConDE & PyData Berlin",
 *  "price": 420.69,
 *  "currency": "EUR",
 *  "quantity": 42,
 * })
 * @param {object} params - The parameters of the click event.
 * @param {string} params.item_id - The unique identifier of the item.
 * @param {string} params.item_name - The name of the item.
 * @param {string} params.item_brand - The brand of the item.
 * @param {string} params.affiliation - The affiliation of the item.
 * @param {number} params.price - The price of the item in the currency (default: 0).
 * @param {string} params.currency - The currency of the price (default: 'EUR').
 * @param {number} params.quantity - The quantity of the item (default: 1).
 */
export function click(
  {
    item_id,
    item_name,
    item_brand,
    affiliation,
    price = 0,
    currency = "EUR",
    quantity = 1,
  },
) {
  console.debug("Tracking event: click", {
    item_id,
    item_name,
    item_brand,
    affiliation,
    price,
    currency,
    quantity,
  })
  gtag("event", "select_item", {
    items: [{
      item_id,
      item_name,
      price,
      currency,
      item_brand,
      affiliation,
      quantity,
    }],
  })

  insightsClient("clickedObjectIDs", {
    authenticatedUserToken: globalThis.userId,
    index: globalThis.algoliaIndexName,
    eventName: "Item Selected",
    objectIDs: [item_id],
  })
}

globalThis.track = globalThis.track || {
  addToCart,
  booking,
  click,
}
